
import { defineComponent } from 'vue'

// axios
import axios from 'axios'

// dropdown
import dropdown from 'vue-dropdowns';

// upload
import { upload } from 'upload';

// Import Custom Components
import SectionHeader from "@/components/SectionHeader.vue"
import MovieItem from "@/components/MovieItem.vue";

export default defineComponent({
    name: 'Admin',
    components: {
        SectionHeader,
        MovieItem,
        dropdown
    },
    data() {
        return {
            sectionIcon: '/assets/icons/film-outline.svg',
            sectionName: 'Películas',
            isLoading: true,
            nowPlaying: [] as any[any],
            noPoster: '/assets/img/no_poster.jpg',
            saveStatus: false,
            cinemaSelectOptions: [] as any[any]
        }
    },
    async created() {

        //create selector for cinemas
        this.$store.state.cinemas.forEach((cinema: any) => {
            this.cinemaSelectOptions.push({ id: cinema.cinemaId, name: cinema.cinemaName })
        });

        //set default cinema
        let cinemaId = this.$store.state.cinemas[0].cinemaId

        //get selected cinema, if any
        if (this.$store.state.selectedCinema) {
            cinemaId = this.$store.state.selectedCinema
        } else {
            this.$store.commit('setSelectedCinema', cinemaId)
        }

        this.getNowPlaying(cinemaId)
    },
    methods: {
        async getNowPlaying(cinemaId: number) {
            this.isLoading = true

            const apiUrl = (this as any).apiUrl

            //get now playing
            const getNowPlaying = await axios.get(apiUrl + '/nowPlaying/' + cinemaId).catch(error => {
                console.log(error.response)
                throw 'Error de Servidor'
            })

            this.nowPlaying = getNowPlaying.data.data

            this.isLoading = false
        },
        upload: async function (event: Event, movieId: string, contentType: string) {

            // get file input
            const input = event.target

            //get file
            const file = (input as any).files[0]

            //get api url
            const apiUrl = (this as any).apiUrl

            //get cinema info
            const cinemaId = this.$store.state.selectedCinema

            //get company id
            const companyId = this.$store.state.user.companyId

            const response = await upload(
                apiUrl + '/file',
                {
                    file: file as File,
                    cinemaId: cinemaId,
                    companyId: companyId,
                    movieId: movieId,
                    contentType: contentType
                }
            )

            /*const data = JSON.parse(response.data as any)

            this.nowPlaying.forEach((movie: any[any], index: number) => {
                if (movie.codigoPelicula == movieId) {
                    this.nowPlaying[index].poster = data.url
                }
            });*/
            this.getNowPlaying(cinemaId)
        },
        updateSelectedCinema(cinemaInfo: any[any]) {
            const cinemaId = cinemaInfo.id
            this.$store.commit('setSelectedCinema', cinemaId)
            this.getNowPlaying(cinemaId)
        },
        getCurrentCinemaName(cinemaId: number) {
            let cinemaName = 'Seleccione un cine'

            this.$store.state.cinemas.forEach((cinema: any) => {
                if (cinema.cinemaId == cinemaId) {
                    cinemaName = cinema.cinemaName
                }
            })

            return cinemaName
        },
        async removeFile(fileId: number) {
            //get cinema info
            const cinemaId = this.$store.state.selectedCinema

            //get api url
            const apiUrl = (this as any).apiUrl

            //payload
            const payload = {
                cinemaId: cinemaId,
                fileId: fileId
            }

            //delete banner
            await axios.delete(apiUrl + '/file/'+fileId).catch(error => {
                console.log(error.response)
                throw 'Error de Servidor'
            })

            //get movies
            this.getNowPlaying(this.$store.state.selectedCinema)
        }
    },
    watch: {
        $route() {
            console.log(this.$route.params)
        }
    }
})


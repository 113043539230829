import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c047b58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "movieItem" }
const _hoisted_2 = {
  key: 2,
  class: "text-white truncate font-semibold text-sm mt-2 opacity-75 lg:text-lg"
}
const _hoisted_3 = {
  key: 3,
  class: "h-6 w-full bg-neutral rounded-sm mt-3 animate-pulse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.poster)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "poster relative bg-neutral border border-stone rounded-md overflow-hidden",
          style: _normalizeStyle($props.poster ? { backgroundImage: 'url(' + $props.poster + ')' } : '')
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["poster bg-neutral border border-stone rounded-md", ( !_ctx.$slots.default ) ? 'animate-pulse' : null])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)),
    ($props.title && $props.showTitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.title), 1))
      : ($props.showTitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3))
        : _createCommentVNode("", true)
  ]))
}

import './components.css'

export default {
	name: 'MovieItem',
	props: {
		title: {
			type: String
		},
		poster: {
			type: String
		},
		showTitle: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		trimTitle: function(title: string){
			if(title.length > 30){
				return title.substring(0, 30) + '...'
			}else{
				return title
			}
		}
	}
}
